<template>
  <div>
    <b-table
      v-if="receipts.length"
      ref="refBillingDNTable"
      class="position-relative event_list shadow-table"
      :items="receipts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      caption-top
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #table-caption>
        <span class="fs-15 weight-500 text-capitalize caption-text">Receipts</span>
      </template>
      <template #cell(CN_Trx_No)="data">
        <div
          v-if="data.item.CN_Type.toLowerCase() == 'book_receipt'"
          class="text-nowrap"
        >
          <b-button
            v-if="canViewThisAction('show', 'Receipt')"
            variant="link"
            class="p-0 weight-600"
            :to="{ name: 'customers-receipts-show', params: { id: data.item.CN_Trx_Id } }"
          >
            {{ data.item.CN_Trx_No }}
          </b-button>
          <span
            v-else
            class=""
          >{{ data.item.CN_Trx_No }}</span>
          <br>
          <span v-if="data.item.Created_On">{{ dateFormat(data.item.Created_On) }}</span>
        </div>
        <div
          v-else
          class="text-nowrap"
        >
          <span class="weight-600">{{ data.item.CN_Trx_No }}</span>
          <br>
          <span v-if="data.item.Created_On">{{ dateFormat(data.item.Created_On) }}</span>
        </div>
      </template>
      <template #cell(CN_Item)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.CN_Type }} ({{ data.item.CN_Item }})</span>
        </div>
      </template>
      <template #cell(Allocated_Amount)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.Allocated_Amount) }}</span>
        </div>
      </template>
      <template #cell(Created_On)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.Created_By }}</span>
          <br>
          <span v-if="data.item.Created_On">{{ dateFormat(data.item.Created_On) }}</span>
        </div>
      </template>
      <template #cell(Updated_On)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.Updated_By || '-' }}</span>
          <br>
          <span v-if="data.item.Updated_On">{{ dateFormat(data.item.Updated_On) }}</span>
        </div>
      </template>
      <template #bottom-row="">
        <td
          class="bottom-row"
          colspan="7"
        >
          <span class="weight-500 fs-18">Total Receipt {{ getTotal() }}</span>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No receipts found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    receipts: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'TrxDetail_Id',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'CN_Trx_No',
          label: 'trx No',
          sortable: true,
        },
        {
          key: 'CN_Item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'Allocated_Amount',
          label: 'amount',
          sortable: true,
        },
        {
          key: 'Payment_Type',
          label: 'payment type',
          sortable: true,
        },
        {
          key: 'Remark',
          label: 'remarks',
          sortable: true,
        },
        {
          key: 'Created_On',
          label: 'created by',
          sortable: true,
        },
        {
          key: 'Updated_On',
          label: 'last updated by',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      const separateLines = text.split(/\r?\n|\r|\n/g)
      if (separateLines[index]) {
        return separateLines[index]
      }

      return ''
    },
    getTotal() {
      const total = this.receipts.reduce((n, { Allocated_Amount }) => n + Number(Allocated_Amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
