<template>
  <div>
    <b-table
      v-if="billings.length"
      ref="refReceiptCNTable"
      class="position-relative event_list shadow-table"
      :items="billings"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(index)="data">
        <div class="text-nowrap">
          <span class="">{{ data.index + 1 }}</span>
        </div>
      </template>
      <template #cell(Created_On)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.Created_By }}</span>
          <br>
          <span v-if="data.item.Created_On">{{ dateFormat(data.item.Created_On) }}</span>
        </div>
      </template>
      <template #cell(Unit_Price)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.Unit_Price) }}</span>
        </div>
      </template>
      <template #cell(TrxDetail_Amt)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.TrxDetail_Amt) }}</span>
        </div>
      </template>
      <template #custom-foot>
        <tr>
          <td
            colspan="6"
            class="text-right"
          >
            <span class="weight-600">Subtotal</span>
          </td>
          <td>
            <span class="weight-600">{{ getTotal() }}</span>
          </td>
        </tr>
        <tr>
          <td
            colspan="6"
            class="text-right"
          >
            <span class="weight-600">8% GST</span>
          </td>
          <td>
            <span class="weight-600">$0</span>
          </td>
        </tr>
        <tr>
          <td
            class="bottom-row"
            colspan="7"
          >
            <span class="weight-500 fs-18">Total Debit {{ getTotal() }}</span>
          </td>
        </tr>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No billings data found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    billings: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'CN_Trx_No',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'index',
          label: 'No',
          sortable: true,
        },
        {
          key: 'DN_Item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'Quantity',
          label: 'quantity',
          sortable: true,
        },
        {
          key: 'Remark',
          label: 'remarks',
          sortable: true,
        },
        {
          key: 'Created_On',
          label: 'created by',
          sortable: false,
        },
        {
          key: 'Unit_Price',
          label: 'unit price',
          sortable: true,
        },
        {
          key: 'TrxDetail_Amt',
          label: 'Amount',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      if (text) {
        const separateLines = text.split(/\r?\n|\r|\n/g)
        if (separateLines[index]) {
          return separateLines[index]
        }
      }

      return ''
    },
    getTotal() {
      const total = this.billings.reduce((n, { TrxDetail_Amt }) => n + Number(TrxDetail_Amt), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
